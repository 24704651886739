import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage16() {
  const question = {
    title: 'Frage 16 von 35',
    headline: '',
    subline: 'Administrative und organisatorische Aufgaben im Bereich',
  }

  const answers = [
    {
      name: 'Administrative-Organisation',
      id: 'uebernehmen',
      value: 'Übernehme ich gern eigenverantwortlich.',
      desc: '',
    },
    {
      name: 'Administrative-Organisation',
      id: 'unterstuetzen',
      value: 'Dabei unterstütze ich gern.',
      desc: '',
    },
    {
      name: 'Administrative-Organisation',
      id: 'patienten-kuemmern',
      value: 'Ich kümmere mich lieber um die Patienten.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-purple">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="purple"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="turkis"
              index={16}
            />
          </div>
        </section>

        <MatchingNavigation
          color="purple"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-17`}
          prev={`${AREA_CARE_PATH}/spielen/frage-15`}
          index={16}
        />

        <Icon position="1" name="checklist-2" />
      </main>
    </>
  )
}
